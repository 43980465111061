import React, { useState } from "react";
import { Link } from "gatsby";

const Pricing = () => {

	const [annual, chargeAnnually] = useState(false);
	const togglePricing = () => {
		chargeAnnually(!annual);
	}

	const plans = [
		{
			name: "Starter",
			price: 14,
			features: [
				{
					title: 'Unlimited Videos'
				},
				{
					title: 'Unlimited Students'
				},
				{
					title: 'Interactive coding lectures'
				},
				{
					title: '1 course'
				},
				{
					title: '$2 fee per course sale (first 10 students/month no fee**)'
				},
				{
					title: '1 Admin User'
				},
				{
					title: 'Out of the box SEO'
				},
				{
					title: 'Out of the box GDPR compliance'
				},
				{
					title: 'EU & UK VAT reporting'
				},
				{
					title: 'Presales'
				},
				{
					title: 'Student Comments'
				},
				{
					title: 'Blazing fast mobile responsive student site',
					info: false,
					detail: 'Some long description about this feature'
				},
				{
					title: 'Google Analytics integration'
				},
				{
					title: 'Mindmaps and Flashcards via our traverse integration',
					info: true,
					detail: 'https://traverse.link/'
				},
				{
					title: 'Free migrations from your existing platform (we do it for you)',
					info: false,
					detail: 'We will do the work to migrate the course for you if you like'
				},
			]
		},
		{
			name: 'Standard',
			price: 29,
			features: [
				{
					title: 'Up to 5 courses (1 free course)'
				},
				{
					title: '$1 fee per course sale (first 50 sales/month no fee**)'
				},
				{
					title: '3 X 1-on-1 Course Business Coaching Session'
				},
				{
					title: 'Custom domain with SSL/TLS'
				},
				{
					title: 'Tax auto-remittance via paddle'
				},
				{
					title: 'Memberships',
					info: false,
					detail: 'Some long description about this feature'
				},
				{
					title: 'Coupons'
				},
				{
					title: 'Custom Third-Party Dependencies for Code Exercises'
				},
				{
					title: 'Free access to traverse creator tools',
					info: true,
					detail: 'https://traverse.link/'
				},
			]
		},
		{
			name: 'Maker',
			price: 65,
			features: [
				{
					title: 'Unlimited courses'
				},
				{
					title: '$0.25 fee per course sale (first 50 sales/month no fee**)'
				},
				{
					title: 'Monthly 1-on-1 Course Business Coaching Sessions'
				},
				{
					title: 'Priority suppport',
				},
			]
		},
	];

	return (
		<section id="pricing" class="py-16 lg:py-24 bg-indigo-100">
			<div class="container">
				<div className="mx-auto lg:w-10/12 inner">
					<h2 class="text-center mb-6">Plans to support you whether you're starting out or expanding</h2>
					<div className="my-6 text-center text-gray-700 annual-plan">
						<div className="inline-flex items-center custom-checkbox">
							<input id="checkbox" type="checkbox" onChange={togglePricing} />
							<div className="toggle"></div>
							<label className="pl-3" htmlFor="checkbox">Charge annually
								<span className="text-green-500"> (save 50%)</span>
							</label>
						</div>
					</div>


					<div className="flex flex-wrap -mx-3 lg:-mx-4 card-list">
						{plans.map((plan) => {
							return (
								<div className="w-full p-3 lg:px-4 md:w-4/12">
									<div className="flex flex-wrap h-full px-8 py-6 bg-white rounded-lg shadow transition-shadow duration-300 hover:shadow-xl card">
										<div className="w-full">
											<h3 className={`
											${ plan.name === 'Standard' ? 'text-blue-500' : '' }
											${ plan.name === 'Maker' ? 'text-green-500' : '' }
											text-center`}>{plan.name}</h3>
											<div className="flex items-center justify-center pricing">
												<div className="text-2xl font-bold">$
													{annual ? (
														(plan.price) - (plan.price * 0.5)
													) : (
														plan.price
													)}
												</div>
												{ annual ? ((<div>/mo</div>)) : (<div>/mo</div>) }
											</div>
											<hr className="my-4" />
											<ul className="mb-6 text-sm text-gray-700 features space-y-2">
												{plan.name === 'Standard' && (
													<li className="font-bold">Everything in Starter, plus:</li>
												)}
												{plan.name === 'Maker' && (
													<li className="font-bold">Everything in Standard, plus:</li>
												)}
												{plan.features.map((feature) => {
													return (
														<li>
															{feature.title}
															{feature.info && (
																<a href={feature.detail} target="_blank"><span className="ml-2 text-black">&#9432;</span></a>
															)}
														</li>
													)
												})}
											</ul>
											<a className="link" href="https://docs.google.com/spreadsheets/d/1TfSGjYX5eKVpK3Ndmw20UlzzSL9mEoyAAiAaHF5mmD4/edit#gid=0" target="_blank">**View pricing illustration</a>

										</div>
										<div className="pt-2 w-full mt-auto text-center">
											<Link to="https://app.coursemaker.org/sign-up" className="block button">Get Started</Link>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
}

export default Pricing;
