import React, {useState} from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from "../components/seo";
import Pricing from "../components/pricing";
import FastAPIClient, {formatError} from "../fast_api_client"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '/src/css/page.css'

const client = new FastAPIClient()

const CodeEditor = (props) => {
	if (typeof window !== 'undefined') {
		const CodeEditor = require("@coursemaker/school-components").CodeEditor
		return <CodeEditor {...props}/>
	}
	return <></>;
}

const starterCodeTemplate = {
	"code_execution_backend": "JUDGE0_EXTRA_CE",
	"cpu_time_limit": 30,
	"expected_output": "I am capitalized",
	"hints_markdown": "	",
	"id": "212",
	"language_id": "python37",
	"source_code": "# !/bin/python\n\nimport pandas as pd\nimport numpy as np\n\n# Create a random DataFrame\nrandom_df = pd.DataFrame(np.random.rand(4, 8))\n\n# Task: capitalize the string with `capitalize()` \nexample_string = 'i am capitalized'\nprint(example_string)\n",
	"stdin": "",
	"title": "",
	"wall_time_limit": 30
}

export default ({ pageContext, location }) => {
	const [codeSubmissionTemplate, setCodeSubmissionTemplate] = useState(starterCodeTemplate)
	  const seo = {
			frontmatter: {
				// By default, get the slug from the page location.
				slug: location.pathname.replace(/[^\w\d-]/g, ''),
				...pageContext.frontmatter,
			},
  	};
	return (
		<Layout footerHr="true">
			<SEO {...seo} />
			<section id="home-hero" class="text-center overflow-hidden">
				<div className="relative py-20 pb-10 content">
					<div className="absolute top-0 bottom-0 left-0 w-full bg-indigo-100 rounded-bg bottom">
					</div>
					<div className="container relative">
						<div className="mx-auto xl:w-6/12 lg:w-8/12">
							<h2 className="mb-2 md:mb-0">The Online Course Builder for Technical Makers</h2>
							<h4 className="mb-4 text-xl text-gray-600 lg:text-2xl">Easy. Elegant. Affordable.</h4>
							<p>Interactive coding lectures your students will love</p>
							<p>Ideal for science | software | data </p>
							<div className="mt-10 button-wrapper text-wrapper">
								<Link to="https://app.coursemaker.org/sign-up" className="mb-2 button">Get Started for Free</Link>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="py-12 lg:py-20">
				<div className="container">
					<div className="flex flex-wrap items-center justify-between mx-auto lg:w-11/12">
						<div className="mb-12 w-full lg:w-5/12 lg:mb-0">
							<h2 className="mb-6 leading-tight">Interactive coding lectures</h2>
							<p>The future of online courses is interactive. No setup. Students can write code in their browser.</p>
							<hr className="my-6" />
							<ul className="w-checkmark">
								<li>Fully interactive coding lectures - <a href="https://www.youtube.com/watch?v=drY8AKWak68" target="_blank" className="link hover:underline">show me</a></li>
								<li>Python, Javascript, Typscript, Java, Go, C#, and Rust (more coming soon)</li>
								<li>Beautiful code syntax highlighting</li>
								<li>Write Math properly (KaTeX)</li>
							</ul>
							<Link to="https://app.coursemaker.org/sign-up" className="mt-6 button">Get Started</Link>
						</div>
						<div className="w-full lg:w-6/12">
							<h4>Try it out 👇</h4>
							<CodeEditor
								codeSubmissionTemplate={codeSubmissionTemplate}
								setCodeSubmissionTemplate={setCodeSubmissionTemplate}
								createCodeSubmission={(sourceCode, expectedOutput, codeExecutionBackend, languageID) => client.createCodeSubmission(sourceCode, expectedOutput, codeExecutionBackend, languageID)}
								getCodeSubmission={(token, codeExecutionBackend) => client.getCodeSubmission(token, codeExecutionBackend)}
								getSupportedLanguages={() => client.getSupportedLanguages()}
								displayError={(err) => toast.warning(formatError(err), { variant: 'error', autoHideDuration: 5000 })}
								editable={false}
							>
							</CodeEditor>
						</div>
					</div>
				</div>
			</section>

			<br />
			<br />

			<section>
				<div className="container relative">
					<div className="w-full pb-5 mx-auto lg:w-8/12 xl:w-6/12">
						<img className="block w-full shadow-lg" src={`../../home-elon.jpg`} alt="Home 1" />
					</div>
				</div>
			</section>

			<section id="product" className="py-16 lg:py-20">
				<div className="container">
					<h2 className="mb-12 md:text-center">Everything you need in one place</h2>
					<img className="block w-full mx-auto lg:w-9/12" src={`../../home-2-new.png`} alt="Home 2" />
				</div>
			</section>

			<section className="py-12 lg:py-20">
				<div className="container">
					<div className="flex flex-wrap items-center justify-between mx-auto lg:w-11/12">
						<div className="mb-12 lg:w-5/12 lg:mb-0">
							<h2 className="mb-6">Grow Your Online Course Business</h2>
							<p>CourseMaker supports you every step, and offers advanced features as standard. From ultra-affordable 
							memberships to detailed student analytics, we focus on giving you the tools you need to grow your course business.</p>
							<hr className="my-6" />
							<ul className="w-checkmark">
								<li>Memberships available</li>
								<li>Unlimited video and students on all plans </li>
								<li>Custom domains + SSL</li>
								<li>Coupons</li>
								<li>Precise tracking and student analytics</li>
								<li>You own your content and email list</li>
							</ul>
							<Link to="https://app.coursemaker.org/sign-up" className="mt-6 button">Get Started</Link>
						</div>
						<div className="lg:w-6/12">
							<img className="block w-full" src={`../../home-3.png`} alt="Home 3" />
						</div>
					</div>
				</div>
			</section>

			<section className="py-12 lg:py-20">
				<div className="container">
					<div className="flex flex-wrap items-center justify-between mx-auto lg:w-11/12">
						<div className="mb-12 lg:w-5/12 lg:mb-0 lg:order-1">
							<h2 className="mb-6 leading-tight">Tax & Compliance Handled</h2>
							<p>We are GDPR compliant. All domains get SSL/TLS certificates out of the box. No headaches for you.</p>
							<hr className="my-6" />
							<ul className="w-checkmark">
								<li><b>Complete Tax Auto-Remittance</b> (US sales tax, EU/UK VAT, Indian GST, Australian GST and more) via <a target="_blank" className="link hover:underline" href="https://paddle.com/">Paddle</a></li>
								<li>UK & EU VAT Reporting with Stripe</li>
								<li>GDPR Compliant</li>
								<li>Secure Videos</li>
							</ul>
							<Link to="https://app.coursemaker.org/sign-up" className="mt-6 button">Get Started</Link>
						</div>
						<div className="lg:w-6/12">
							<img className="block w-full" src={`../../home-4.png`} alt="Home 4" />
						</div>
					</div>
				</div>
			</section>

			<section className="py-24">
				<div className="container">
					<div className="flex flex-wrap items-center justify-between mx-auto lg:w-11/12">
						<div className="mb-12 lg:w-4/12 lg:mb-0">
							<h2 className="mb-6 leading-tight">A Seamless Course Creation Experience</h2>
							<p className="leading-relaxed">Your time is valuable. We make the creation process as frictionless as possible so that you can get things done quickly. With CourseMaker, you can work with tools you already know.</p>
							<hr className="my-6" />
							<ul className="w-checkmark">
								<li>Clean course building interface</li>
								<li>Free migrations from any other platform - yes, really!</li>
								<li>Support 7 days per week, 365 days per year</li>
								<li>Write your lectures, code, charts and equations with markdown</li>
							</ul>
							<Link to="https://app.coursemaker.org/sign-up" className="mt-6 button">Get Started</Link>
						</div>
						<div className="lg:w-7/12">
							<img className="block w-full" src={`../../home-5.png`} alt="Home 5" />
						</div>
					</div>
				</div>
			</section>

			<section className="py-24 bg-indigo-100">
				<div className="container">
					<div className="flex flex-wrap items-center mx-auto lg:w-11/12">
						<div className="mb-12 lg:w-4/12 lg:mb-0">
							<h2 className="mb-6 leading-tight">Blazing Fast Student Experience</h2>
							<p>Technology is at the heart of what we do. Your site will load crazily fast, and it will look great on all screen sizes.</p>
							<hr className="my-6" />
							<ul className="w-checkmark">
								<li>Blazing fast student experience</li>
								<li>SEO out of the box</li>
								<li>Student site optimized for all screen sizes</li>
							</ul>
							<Link to="https://app.coursemaker.org/sign-up" className="mt-6 button">Get Started</Link>
						</div>
						<div className="lg:w-2/12"></div>
						<div className="lg:w-4/12">
							<img className="block w-full" src={`../../home-6.png`} alt="Home 6" />
						</div>
					</div>
				</div>
			</section>

			<section id="home-why" className="relative py-16 overflow-hidden text-white bg-indigo-100 lg:py-24">
				<div style={{ background: '#0B0057' }} className="absolute top-0 bottom-0 left-0 w-full bg-indigo-100 rounded-bg top"></div>
				<div className="container relative mt-16">
					<h2 className="mb-10 text-center text-green-500">Why Choose CourseMaker?</h2>
					<div className="mx-auto lg:w-7/12">
						<div className="flex flex-wrap justify-between">
							<div className="lg:w-5/12">
								<img className="h-16 mb-3" src="../../home-icon-1.svg" alt="Talk to us" />
								<h3 className="mb-3 text-2xl font-bold text-green-500">Talk to us</h3>
								<p>Everyone says they have great customer service. Send us a question - go ahead! See how fast we reply.</p>
							</div>
							<div className="w-full h-px mt-10 mb-8 bg-white lg:w-px bg-opacity-25 separator lg:my-0 lg:h-auto"></div>
							<div className="lg:w-5/12">
								<div className="inline-flex h-16 mb-3 align-items">
									<img className="w-10/12" src="../../home-icon-2.svg" alt="For developers" />
								</div>
								<h3 className="mb-3 text-2xl font-bold text-green-500">Open-Source</h3>
								<p className="mb-3">Our course GatsbyJS Theme is open-source. You never have to worry about lock-in.</p>
								<Link className="link hover:underline" to="https://github.com/CourseMaker/gatsby-theme-coursemaker/">View Code</Link>
							</div>
						</div>
					</div>

					<div className="mx-auto mt-24 text-center lg:w-6/12 testimonial">
						<img className="inline-block mb-4" src="../../rating.svg" alt="Rating" />
						<p className="text-xl">“CourseMaker's tech has allowed us to deliver an amazing online course.”</p>
						<div className="flex items-center justify-center mt-10 text-left">
							<img className="object-cover w-12 h-12 rounded-full" src="../../bien.png" alt="Bien King Image" />
							<div className="ml-4 info">
								<div className="font-bold">Bien King</div>
								<div className="text-sm font-light">CEO of <Link to="https://www.letsreinvent.org/">Let's Reinvent</Link> & Course Author</div>
							</div>
						</div>

						<div className="mx-auto mt-24 text-center lg:w-6/12 testimonial">
							<img className="inline-block mb-4" src="../../rating.svg" alt="Rating" />
							<p className="text-xl">“For course makers that want to mix videos sessions, markdown sessions and coding exercises,
								this is a no brainer. Currently, you can offer coding sessions on Python, Javascript, Go, C#...and...Machine Learning with all the dependencies like numpy, pandas, scikit-learn...AMAZING”</p>
							<div className="flex items-center justify-center mt-10 text-left">
								<img className="object-cover w-12 h-12 rounded-full" src="../../rod.jpeg" alt="Rod Paris Image" />
								<div className="ml-4 info">
									<div className="font-bold">Rod Paris</div>
									<div className="text-sm font-light">Course Author</div>
								</div>
							</div>
						</div>

						<br />
						<br />
						<blockquote class="twitter-tweet">
							<p lang="en" dir="ltr">I just moved Python for Marketers off Teachable and onto Course Maker --&gt; <a href="https://t.co/034keZqAmp">https://t.co/034keZqAmp</a> (same url).</p>&mdash; Michael Taylor (@hammer_mt) <a href="https://twitter.com/hammer_mt/status/1372142351431446530?ref_src=twsrc%5Etfw">March 17, 2021</a>
						</blockquote>
						<br />
						<br />

						<br/>
						<br/>
							<blockquote className="twitter-tweet">
								<a href="{https://twitter.com/sithankanna/status/1408868806102073351?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed%7Ctwterm%5E1408868806102073351%7Ctwgr%5E%7Ctwcon%5Es1_c10&ref_url=https%3A%2F%2Fpublish.twitter.com%2F%3Fquery%3Dhttps3A2F2Ftwitter.com2Fsithankanna2Fstatus2F1408868806102073351widget%3DTweet}"></a>
							</blockquote>
						<br/>
						<br/>

						<blockquote class="twitter-tweet">
							<p lang="en" dir="ltr">Really excited about CourseMaker! Today they shipped the language support for my all-time favorite language - C#, along with Typescript and Rust! <a href="https://t.co/yJWCo5p9oQ">https://t.co/yJWCo5p9oQ</a></p>&mdash; Evgeny Urubkov (@EvgenyUrubkov) <a href="https://twitter.com/EvgenyUrubkov/status/1375850585216315395?ref_src=twsrc%5Etfw">March 27, 2021</a>
						</blockquote>
					</div>
				</div>
			</section>

			<Pricing />

			<div className="pb-16 bg-indigo-100">
				<div className="container">
					<p class="text-center md:w-1/2 mx-auto">
						For custom requirements please <Link to="/contact" className="link">get in touch</Link>
					</p>
				</div>
			</div>
		<ToastContainer />
		</Layout>
	);
};
