const axios = require('axios');



class FastAPIClient {
    constructor() {
        this.config = {
            apiBasePath: process.env.GATSBY_API_BASE_URI,
        };
        this.apiClient = this.getApiClient(this.config);
    }
    /* ----- Client Configuration ----- */

    /* Create Axios client instance pointing at the REST api backend */
    getApiClient(config) {
        const initialConfig = {
            baseURL: `${config.apiBasePath}/api/v1`,
        };
        const client = axios.create(initialConfig);
        return client;
    }

    /* ----- Code Labs ------ */
    // Code Execution
    createCodeSubmission(sourceCode, expectedOutput, codeExecutionBackend, languageID){
        return this.apiClient.post(`/code/submissions`, {
            source_code: sourceCode,
            expected_output: expectedOutput,
            language_id: languageID,
            code_execution_backend: codeExecutionBackend
        })
            .then((resp) => resp);
    }

    getCodeSubmission(codeSubmissionToken, codeExecutionBackend){
        return this.apiClient.post(`/code/submissions/poll`,{
            token: codeSubmissionToken,
            code_execution_backend: codeExecutionBackend
        }).then(
            (resp) => resp
        );
    }

    getSupportedLanguages(){
        return this.apiClient.get(`/code/supported-languages`).then(
            (resp) => resp
        );
    }
}

export function formatError(err){
    // TODO: We should make this work for CMS API errors too and try to use this in all catch blocks

    const detail = err?.response?.data?.detail
    if(typeof(detail) === 'string'){
        // If simple string error message
        return detail
    } else if(typeof(detail) === 'object'){
        // If standard backend formatted validation error
        // Example error format: [{"loc":["body","custom_domain_in","school_id"],"msg":"str type expected","type":"type_error.str"}]
        return `Error: `+ detail.map((d) => `the field ${d.loc.slice(-1)[0]} has the following error: ${d.msg}`).join(' and ')
    } else if(typeof(err) === 'object'){
        // If unknown JSON
        return JSON.stringify(err)
    }
    // If Javascript Error
    return err.toString()
}

export default FastAPIClient;
